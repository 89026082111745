const colors = {
  primary: "#53B1BF",
  secondary: "#285D9A",
  quoted: "#293447",
  complete: "#44b700",
  white: "#ffff",
  hexGray: "#FAFAFA",
  bgColor: '#f2f5f9',
  shadow: "##8D8D8D29",
  subFont: "#1b2842",
  skyBlue: "#c3f1f7",
  bg1: "#c9d5f5",
  front1: "#4667b8",
  front2: "#0595e8",
  bg2: "#c8eefa",
  frontHeart: "#db4064",
  bgHeart: "#f2c7d1",

}

export default colors